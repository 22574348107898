import React from 'react';

 interface ConfirmationModalProps{ 
  message:string, 
  onConfirm:any, 
  onCancel:any
 }
const ConfirmationModal = ({ message,onConfirm,onCancel }: ConfirmationModalProps) => {
  return (
    <div className="fixed h-screen inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg">
        {/* <div className="flex justify-center mb-4">
      <img
         
            src={"/images/icon/alert-icon.png"}
            alt="alert icon"
          />
          </div> */}
        <p className=" font-bold text-black">{message}</p>
        <div className="flex space-x-8 item-center justify-center mt-6">
          <button type="submit" className="text-white  focus:ring-4 bg-success hover:bg-green-700 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={onCancel}>Cancel</button>
          <button type="submit" className="text-white bg-danger hover:bg-danger  focus:ring-4  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={onConfirm}>Delete</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;