import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from "yup";
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';
import { getAccessToken } from '../services/http-common';
import { Link, useRoutes } from 'react-router-dom';
import routes from '../routes';

interface SearchOrderInterface {
  onCancel: any;
}

const SearchOrder = ({ onCancel }: SearchOrderInterface) => {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [orderId, setOrderId] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const orderId = e.target.value;
    if (orderId) {
      setOrderId(orderId);
    }
  };

  const schema = Yup.object().shape({
    orderId: Yup.string().required("Order ID is required"),
  });

  const onSubmit = async () => {
    try {
      await schema.validate({ orderId }, { abortEarly: false });
      setErrors({});
      window.location.href=`/orders/details/${orderId}`;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        let newErrors: Record<string, string> = {};
        error.inner.forEach((e) => {
          if (e.path) {
            newErrors[e.path] = e.message;
          }
        });
        setErrors(newErrors);
      } else {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="fixed h-screen w-full z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <div className="mb-4">
          <label className="mb-3 block text-sm font-medium text-black dark:text-white">
            Order ID<span className="text-meta-1">*</span>
          </label>
          <input
            type="text"
            name="orderId"
            onChange={handleChange}
            placeholder="Enter order ID"
            className="w-full rounded border-[1.5px] border-primary bg-transparent py-2 px-8 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          />
          {errors.orderId && (
            <span className="text-sm text-danger">
              {errors.orderId}
            </span>
          )}
        </div>
        
        <div className="flex justify-between">
          <button type="button" className="text-white focus:ring-4 bg-success hover:bg-green-700 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={onCancel}>
            Cancel
          </button>
          <button type="button" className="text-white bg-primary hover:bg-indigo-800 focus:ring-4 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={onSubmit}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchOrder;
