import { lazy } from 'react';
import CategoryList from '../pages/Category/CategoryList';
import CategoryDetails from '../pages/Category/CategoryDetails';
import Orders from '../pages/Order/Orders';
import AllUsers from '../pages/User/Users';
import UpdateUser from '../pages/User/UpdateUser';
import AddUser from '../pages/User/add';
import AllCustomers from '../pages/Customer/CustomerList';
import AddCustomer from '../pages/Customer/AddCustomer';
import CustomerDetails from '../pages/Customer/CustomerDetails';
import UpdateCustomer from '../pages/Customer/UpdateCustomer';
import UserDetails from '../pages/User/UserDetails';
import OrderDetails from '../pages/Order/OrderDetails';
import CancelledOrders from '../pages/Order/CancelledOrders';
import PendingOrders from '../pages/Order/PendingOrders';
import OptionList from '../pages/Option/OptionList';
import OptionMediaImages from '../pages/Option/OptionMediaImages';
const ImageUploadForm =lazy(()=>import('../pages/Product/ImageUploadForm'));
const Calendar = lazy(() => import('../pages/Calendar'));
const Chart = lazy(() => import('../pages/Chart'));
const FormElements = lazy(() => import('../pages/Form/FormElements'));
const FormLayout = lazy(() => import('../pages/Form/FormLayout'));
const Profile = lazy(() => import('../pages/Profile'));
const Settings = lazy(() => import('../pages/Settings'));
const Tables = lazy(() => import('../pages/Tables'));
const Alerts = lazy(() => import('../pages/UiElements/Alerts'));
const Buttons = lazy(() => import('../pages/UiElements/Buttons'));
const AddProduct = lazy(()=> import('../pages/Product/AddProduct'));
const AddMainMenus = lazy(()=>import('../pages/MainMenus/AddMenu'));
const AddCategory = lazy(()=> import('../pages/Category/AddCategory'));
const ProductList = lazy(()=>import('../pages/Product/ProductList'));
const AddTag =lazy(()=> import('../pages/Tags/AddTags'));
const AddOption = lazy(()=>import('../pages/Option/AddOption'));
const ProductDetails=lazy(()=>import('../pages/Product/ProductDetails'));
const UpdateProduct=lazy(()=>import('../pages/Product/UpdateProduct'));
const UpdateCategory=lazy(()=>import('../pages/Category/UpdateCategory'));
const UpdateMenu=lazy(()=>import('../pages/MainMenus/UpdateMenu'));
const UpdateOption=lazy(()=>import('../pages/Option/UpdateOption'));
const UpdateTag=lazy(()=>import('../pages/Tags/UpdateTag'));

const coreRoutes = [
  {
    path: '/calendar',
    title: 'Calender',
    component: Calendar,
  },
  {
    path: '/product/addproduct',
    title: 'Add_Product',
    component: AddProduct,
  },
  {
    path: '/products',
    title: 'Products',
    component: ProductList,
  },

  {
    path: '/users',
    title: 'Users',
    component: AllUsers,
  },

  {
    path: '/customers',
    title: 'Customers',
    component: AllCustomers,
  },
  {
    path: '/customer/add',
    title: 'Add_Customer',
    component: AddCustomer,
  },

  {
    path: '/users/details/:userId',
    title: 'User details',
    component: UserDetails,
  },
  {
    path: '/customers/details/:customerId',
    title: 'Customer_details',
    component: CustomerDetails,
  },

  {
    path: '/customers/update/:customerId',
    title: 'Customer_Update',
    component: UpdateCustomer,
  },

  {
    path: '/users/add',
    title: 'Add User',
    component: AddUser,
  },

  {
    path: '/upload/:productId',
    title: 'Upload Image',
    component: ImageUploadForm,
  },
  {
    path: '/product/details/:productId',
    title: 'Product_details',
    component: ProductDetails,
    
  },
  {
    path: '/product/update/:productId',
    title: 'Update_Product ',
    component: UpdateProduct,
    
  },

  {
    path: '/users/update/:userId',
    title: 'Update User ',
    component: UpdateUser,
  },

  {
    path: '/orders',
    title: 'All orders ',
    component: Orders,
    
  },

  {
    path: '/orders/cancelled',
    title: 'All orders ',
    component: CancelledOrders,
    
  },

  {
    path: '/orders/pending',
    title: 'All orders ',
    component: PendingOrders,
    
  },

  {
    path: '/orders/details/:orderId',
    title: 'Order details ',
    component: OrderDetails,
    
  },

  {
    path: '/category/update/:categoryId',
    title: 'Update Category ',
    component: UpdateCategory,
    
  },

  {
    path: '/category/details/:categoryId',
    title: ' Category details',
    component: CategoryDetails,
    
  },

  {
    path: '/menu/update/:menuId',
    title: 'Update Main Menu ',
    component: UpdateMenu,
    
  },
  {
    path: '/option/update/:optionId',
    title: 'Update Option ',
    component: UpdateOption,
    
  },
  {
    path: '/tag/update/:tagId',
    title: 'Update Tag ',
    component: UpdateTag,
    
  },
  {
    path: '/tag/addtag',
    title: 'Tag',
    component: AddTag,
  },
  {
    path: '/option/addoption',
    title: 'Option',
    component: AddOption,
  },
  {
    path: '/options',
    title: 'Option',
    component: OptionList,
  },
  {
    path: '/options/media/:optionId',
    title: 'Option',
    component: OptionMediaImages,
  },
  {
    path: '/menu/addmainmenu',
    title: 'Add Main Menu',
    component: AddMainMenus,
  },
  {
    path: '/category/addcategory',
    title: 'Add Category',
    component: AddCategory,
  },
  {
    path: '/categories',
    title: 'Categories',
    component: CategoryList,
  },
  {
    path: '/profile',
    title: 'Profile',
    component: Profile,
  },
  {
    path: '/forms/form-elements',
    title: 'Forms Elements',
    component: FormElements,
  },
  {
    path: '/forms/form-layout',
    title: 'Form Layouts',
    component: FormLayout,
  },
  {
    path: '/tables',
    title: 'Tables',
    component: Tables,
  },
  {
    path: '/settings',
    title: 'Settings',
    component: Settings,
  },
  {
    path: '/chart',
    title: 'Chart',
    component: Chart,
  },
  {
    path: '/ui/alerts',
    title: 'Alerts',
    component: Alerts,
  },
  {
    path: '/ui/buttons',
    title: 'Buttons',
    component: Buttons,
  },
];

const routes = [...coreRoutes];
export default routes;
