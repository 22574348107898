import axios from 'axios';
import { useState, useEffect } from 'react';
import CardTwo from '../../components/CardTwo.tsx';
import TableOne from '../../components/TableOne.tsx';
import { getAccessToken } from '../../services/http-common.tsx';
import { BASEURL, API_SECRET, API_KEY } from '../Constants.tsx';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Link } from 'react-router-dom';
interface OrderInterface {
  orderId?: any | null;
  amount: string;
  status: string;
  date: string;
} 


dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const ECommerce = () => {

  const [currentorders, setCurrentOrders] = useState<number>(0);
  const [orders, setOrders] = useState<OrderInterface[]>([]);

  const accessToken = getAccessToken();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASEURL}orders/count/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
          },
          method: 'GET',
        });
        const ordersData = await response.data;
        setCurrentOrders(ordersData);

        const orderResponse = await axios.get(`${BASEURL}orders/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
          },
          method: 'GET',
        });
        const totalOrders=orderResponse.data;
        setOrders(totalOrders);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        return null;
      }
    };
    fetchData();
  }, []); 

  const getStartOfWeek = () => dayjs().startOf('week');
  const getEndOfWeek = () => dayjs().endOf('week');

  // Filter orders to only include those from the current week
  const ordersThisWeek = orders.filter(order => {
    const orderDate = dayjs(order.date);
    return orderDate.isSameOrAfter(getStartOfWeek()) && orderDate.isSameOrBefore(getEndOfWeek());
  });


  const countOrdersByStatus = (status:any) => {
    if(orders){
    return ordersThisWeek.filter(order => order.status === status).length;
    }
  };

  // Count for each status
  const pendingCount = countOrdersByStatus('ORDER_PENDING');
  const cancelledCount = countOrdersByStatus('ORDER_CANCELLED');

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
      <Link to="/orders/cancelled">  <CardTwo count={cancelledCount} name="Weekly Cancelled Orders"/></Link>
      <Link to="/orders/pending"> <CardTwo count={pendingCount} name="Weekly Pending Orders"/></Link>
      <Link to="/orders">  <CardTwo count={currentorders} name="Orders"/></Link>
        <CardTwo count={currentorders} name="Visitors"/>
      </div>

      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        {/* <ChartOne />
        <ChartTwo />
        <ChartThree /> */}
        {/* <MapOne /> */}
        <div className="col-span-12 xl:col-span-12">
          <TableOne />
        </div>
        {/* <ChatCard /> */}
      </div>
    </>
  );
};

export default ECommerce;

