import axios from "axios";
import { Formik, Field, ErrorMessage ,Form} from "formik";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import * as Yup from 'yup';
import { API_KEY, API_SECRET, BASEURL } from "../Constants";
import { getAccessToken } from "../../services/http-common";
interface RoleInterface{
    roleId?:any|null;
    name:string;
}
interface userInterface {
    userId?: any | null;
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    password:string
    roleName:string;
  }
  const AddUser  = () => {
      const [initialValues, setInitialValues]=useState<userInterface>({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          password:"",
          roleName:''
      });
  
      const validationSchema = Yup.object({
          firstName: Yup.string().required("First name is required"),
          lastName: Yup.string().required("Last name is required"),
          email: Yup.string().email().required("Email is required"),
          contactNumber: Yup.string().required("Contact number is required"),
          password: Yup.string().required("password number is required"),
          roleName: Yup.string().required("Role is required"),

        });
    const userId = useParams<{ userId: string }>();
    let id = userId?.userId;
    const [roles, setRoles]=useState<RoleInterface[]>([]);
    const [user, setUser] = useState<userInterface>();
    const accessToken = getAccessToken();

    useEffect(() => {
      const fetchUserRoles = async () => {
        try {
          const response = await axios.get(`${BASEURL}role/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'X-API-SECRET': `${API_SECRET}`,
                'X-API-KEY': `${API_KEY}`,
              },            
            method: "GET",
          });
          setRoles(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchUserRoles();
    }, [userId]);
    
   
    const onSubmit = async (values: any, { setSubmitting }: any) => {
      // e.preventDefault();
      try {
        const response = await axios.post(
          `${BASEURL}user/add`,
          values,
          {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type":"application/json",
                'X-API-SECRET': `${API_SECRET}`,
                'X-API-KEY': `${API_KEY}`,
              },
          },
        );
        window.location.href = "/users/";
        
      } catch (error) {
        console.error("Error during user updating:", error);
        // window.location.href = "/users/";
      }
    };
  
  
    return (
      <> 
              <Breadcrumb pageName="Add User " />  
          
            <div className="flex flex-col gap-6">
              {/* <!-- Contact Form --> */}
              <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting ,values}) => (
                <Form>
                  <div className="border-b border-stroke px-6.5 py-2 dark:border-strokedark">
                    <h3 className="text-grey font-medium dark:text-white">
                      User Details{" "}
                     
                    </h3>
                  </div>
                  <div className="p-6.5">
                    <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                      <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          First name
                        </label>
                        <Field
                          type="text"
                          value={values.firstName}
                          name="firstName"
                          placeholder="Enter first name"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                         <ErrorMessage
                            name="firstName"
                            component="div"
                            className=" text-danger"
                          />
                      </div>
  
                      <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          Last name
                        </label>
                        <Field
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          placeholder="Enter last name"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                         <ErrorMessage
                            name="lastName"
                            component="div"
                            className=" text-danger"
                          />
                      </div>
                    </div>
  
                    <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                      <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          Email
                        </label>
                        <Field
                          type="text"
                          name="email"
                          value={values.email}
                          
                          placeholder="Enter email"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                         <ErrorMessage
                            name="email"
                            component="div"
                            className=" text-danger"
                          />
                      </div>
                      <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          Contact number
                        </label>
                        <Field
                          type="text"
                          name="contactNumber"
                          value={values.contactNumber}
                          placeholder="Enter contact number"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary  disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                         <ErrorMessage
                            name="contactNumber"
                            component="div"
                            className=" text-danger"
                          />
                      </div>                
                    </div>

                 
                    <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                      <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          Password
                        </label>
                        <Field
                          type="password"
                          name="password"
                          value={values.password}
                          
                          placeholder="Enter password"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        />
                         <ErrorMessage
                            name="password"
                            component="div"
                            className=" text-danger"
                          />
                      </div>

                          <div className="w-full xl:w-1/2">
                        <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                          Role
                        </label>
                        <Field
                        as="select"
                          name="roleName"
                          placeholder="Enter contact number"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-1 text-black outline-none transition focus:border-primary active:border-primary  disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        >
                            <option value="">Select role</option>
                            { roles.map((role,index)=>(
                              role.name!="ROLE_CUSTOMER" &&(
                                <option key={index} value={role.name}>{role.name}</option> 
                              )
                            ))}
                            </Field>
                         <ErrorMessage
                            name="roleName"
                            component="div"
                            className=" text-danger"
                          />
                      </div>                
                    </div>
                    <div className="w-full xl:mt-8 ">
                    <button type="submit" disabled={isSubmitting} className="flex w-full justify-center rounded bg-primary p-2 font-medium text-gray hover:bg-opacity-90">
                {isSubmitting ? "Adding..." : "Add user"}
                </button>                    
                      </div>
                  </div>
                </Form>
                )}
                </Formik>
              </div>
            </div>
        
      </>
    );
  };
  
  export default AddUser;
  