import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Breadcrumb from '../../components/Breadcrumb';
import * as Yup from 'yup';
import axios from 'axios';
import { BASEURL, API_SECRET, API_KEY } from '../Constants';
import { getAccessToken } from '../../services/http-common';
import AddIcon from '../../images/icon/add-icon.png';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export interface AddressInterface {
  locality: string;
  streetName: string;
  city: string;
  pin: string;
  state: string;
  country: string;
}

interface CustomerInterface {
  customerId?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  companyName: string;
  password: string;
  addresses: AddressInterface[];
}
type Params = {
  customerId: string;
};
const CustomerDetails = () => {
  const { customerId } = useParams<Params>();
  const [initialValues, setInitialValues] = useState<CustomerInterface>({
    customerId: customerId,
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    companyName: '',
    password: '',
    addresses: [
      {
        locality: '',
        streetName: '',
        city: '',
        pin: '',
        state: '',
        country: '',
      },
    ],
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    password: Yup.string().required('Password is required'),
    addresses: Yup.array().of(
      Yup.object().shape({
        locality: Yup.string().required('Locality is required'),
        streetName: Yup.string().required('Street name is required'),
        city: Yup.string().required('City is required'),
        pin: Yup.string().required('Pin is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
      }),
    ),
  });

  const accessToken = getAccessToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customerId != undefined) {
          const response = await axios.get(
            `${BASEURL}customers/${customerId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'X-API-SECRET': `${API_SECRET}`,
                'X-API-KEY': `${API_KEY}`,
              },
            },
          );
          const responseData = response.data;
          setInitialValues(responseData);
        } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const response = await axios.post(`${BASEURL}customers/`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-API-SECRET': `${API_SECRET}`,
          'X-API-KEY': `${API_KEY}`,
        },
      });
      window.location.href = '/customers';
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <div>
        <Breadcrumb pageName="Customer details" />
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
              <div className="flex flex-col gap-9">
                {/* <!-- Input Fields --> */}
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className=" flex space-x-1 border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">
                      Customer Details
                    </h3>
                    <span className=" -mt-1 ">
                      <Link
                        className="hover:text-primary"
                        to={`/customers/update/${customerId}`}
                      >
                        <svg
                          className="feather feather-edit text-primary "
                          fill="none"
                          height="30"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>
                      </Link>
                    </span>
                  </div>
                  <div className="flex flex-col gap-5.5 p-6">
                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        First name
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        disabled
                        placeholder="First name"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>

                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        Last name
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        disabled
                        placeholder="Last Name"
                        className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>

                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        Email
                      </label>
                      <Field
                        type="text"
                        name="email"
                        disabled
                        placeholder="Email"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div>
                      <label className="mb-2 block  text-black dark:text-white">
                        Contact Number
                      </label>
                      <Field
                        type="text"
                        name="contactNumber"
                        disabled
                        placeholder="Contact number"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div>
                      <label className="mb-2 block  text-black dark:text-white">
                        Company Name
                      </label>
                      <Field
                        type="text"
                        name="companyName"
                        disabled
                        placeholder=" Company Name"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <FieldArray name="addresses">
                {({}) => (
                  <div>
                    {' '}
                    {values.addresses.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="relative flex flex-col gap-9 mb-3"
                      >
                        {/* <!-- Textarea Fields --> */}
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                          <div className=" flex justify-between border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                              Address {index + 1}
                            </h3>
                          </div>
                          <div className="flex flex-col gap-5.5 p-6 ">
                            <div className="xl:grid xl:grid-cols-2 gap-4">
                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  Locality
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].locality`}
                                  disabled
                                  placeholder="Locality"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].locality`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>

                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  Street name
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].streetName`}
                                  placeholder=" Street name"
                                  disabled
                                  className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].streetName`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                            <div className="xl:grid xl:grid-cols-2 gap-4 ">
                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  City
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].city`}
                                  disabled
                                  placeholder="City"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].city`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  Pin Code
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].pin`}
                                  disabled
                                  placeholder=" Pin Code"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].pin`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                            <div className="xl:grid xl:grid-cols-2 gap-4 ">
                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  State
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].state`}
                                  disabled
                                  placeholder="State"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].state`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>

                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  Country
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].country`}
                                  disabled
                                  placeholder="Country"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].country`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerDetails;
