import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Breadcrumb from '../../components/Breadcrumb';
import * as Yup from 'yup';
import axios from 'axios';
import { BASEURL, API_SECRET, API_KEY } from '../Constants';
import { getAccessToken } from '../../services/http-common';
import AddIcon from '../../images/icon/add-icon.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface AddressInterface {
  locality: string;
  streetName: string;
  city: string;
  pin: string;
  state: string;
  country: string;
}

interface CustomerInterface {
  customerId?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  companyName: string;
  password: string;
  addresses: AddressInterface[];
}
type Params = {
  customerId: string;
};
const UpdateCustomer = () => {
  const { customerId } = useParams<Params>();
  const [initialValues, setInitialValues] = useState<CustomerInterface>({
    customerId: customerId,
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    companyName: '',
    password: '',
    addresses: [
      {
        locality: '',
        streetName: '',
        city: '',
        pin: '',
        state: '',
        country: '',
      },
    ],
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    addresses: Yup.array().of(
      Yup.object().shape({
        locality: Yup.string().required('Locality is required'),
        streetName: Yup.string().required('Street name is required'),
        city: Yup.string().required('City is required'),
        pin: Yup.string().required('Pin is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
      }),
    ),
  });

  const accessToken = getAccessToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customerId != undefined) {
          const response = await axios.get(
            `${BASEURL}customers/${customerId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'X-API-SECRET': `${API_SECRET}`,
                'X-API-KEY': `${API_KEY}`,
              },
            },
          );
          const responseData = response.data;
          setInitialValues(responseData);
        } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const response = await axios.post(
        `${BASEURL}customers/update/${customerId}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
          },
        },
      );
      window.location.href = `/customers/details/${customerId}`;
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <Breadcrumb pageName="Update Customer" />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
              <div className="flex flex-col gap-9">
                {/* <!-- Input Fields --> */}
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                    <h3 className="font-medium text-black dark:text-white">
                      Customer Details
                    </h3>
                  </div>
                  <div className="flex flex-col gap-5.5 p-6">
                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        First name
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>

                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        Last name
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className=" text-danger"
                      />
                    </div>

                    <div>
                      <label className="mb-2 block text-black dark:text-white">
                        Email
                      </label>
                      <Field
                        type="text"
                        name="email"
                        disabled
                        placeholder="Email"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div>
                      <label className="mb-2 block  text-black dark:text-white">
                        Contact Number
                      </label>
                      <Field
                        type="text"
                        name="contactNumber"
                        placeholder="Contact number"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className=" text-danger"
                      />
                    </div>
                    <div>
                      <label className="mb-2 block  text-black dark:text-white">
                        Company Name
                      </label>
                      <Field
                        type="text"
                        name="companyName"
                        placeholder=" Company Name"
                        className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                    >
                      {isSubmitting ? 'Updating...' : 'Update Customer'}
                    </button>
                  </div>
                </div>
              </div>
              <FieldArray name="addresses">
                {({ push, remove }) => (
                  <div>
                    {' '}
                    {values.addresses.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="relative flex flex-col gap-9 mb-3"
                      >
                        {/* <!-- Textarea Fields --> */}
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                          <div className=" flex justify-between border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                            <h3 className="font-medium text-black dark:text-white">
                              Address {index + 1}
                            </h3>
                            <div className="  ">
                              {index != 0 && (
                                <button
                                  type="submit"
                                  onClick={() => remove(index)}
                                  className="rounded-sm font-bold text-white"
                                >
                                  <svg
                                    className="fill-danger"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                      fill=""
                                    />
                                    <path
                                      d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                      fill=""
                                    />
                                    <path
                                      d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                      fill=""
                                    />
                                    <path
                                      d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                      fill=""
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col gap-5.5 p-6 ">
                            <div className="xl:grid xl:grid-cols-2 gap-4">
                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  Locality
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].locality`}
                                  placeholder="Locality"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].locality`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>

                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  Street name
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].streetName`}
                                  placeholder=" Street name"
                                  className="w-full rounded-lg border-[1.5px] border-primary bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:bg-form-input"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].streetName`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                            <div className="xl:grid xl:grid-cols-2 gap-4 ">
                              <div>
                                <label className="mb-2 block text-black dark:text-white">
                                  City
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].city`}
                                  placeholder="City"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].city`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  Pin Code
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].pin`}
                                  placeholder=" Pin Code"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].pin`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                            <div className="xl:grid xl:grid-cols-2 gap-4 ">
                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  State
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].state`}
                                  placeholder="State"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].state`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>

                              <div>
                                <label className="mb-2 block  text-black dark:text-white">
                                  Country
                                </label>
                                <Field
                                  type="text"
                                  name={`addresses[${index}].country`}
                                  placeholder="Country"
                                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary dark:disabled:bg-black"
                                />
                                <ErrorMessage
                                  name={`addresses[${index}].country`}
                                  component="div"
                                  className=" text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className=" rounded-sm font-bold text-black"
                      onClick={() =>
                        push({
                          locality: '',
                          streetName: '',
                          city: '',
                          pin: '',
                          state: '',
                          country: '',
                        })
                      }
                    >
                      <img
                        src={AddIcon}
                        alt="User"
                        width={28}
                        className=" ml-2"
                      />
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateCustomer;
