import { useEffect, useState } from "react";
import { API_KEY, API_SECRET, BASEURL } from "../Constants";
import { getAccessToken } from "../../services/http-common";
import { Link } from "react-router-dom";
import Tick from "../../images/icon/tick-image.png"
import Breadcrumb from "../../components/Breadcrumb";
interface UserInterface {
  userId?: any | null;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  contactNumber: string;
  status: string;
  roles: [
    {
      roleId?: any | null;
      name: string;
    },
  ];
}



const AllUsers = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(7); // Number of items per page
  const [totalPages, setTotalPages] = useState<number>(0);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData(currentPage,perPage);
  }, [currentPage, perPage]); // Empty dependency array means this effect runs only once after the component mounts

  const accessToken = getAccessToken();

  const fetchData = async (page: number, perPage: number) => {
    try {
      const response = await fetch(`${BASEURL}user/`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
          },       
           method: "GET",
      });
      const data = await response.json();
      const totalPages = Math.ceil(data.length / perPage);
      setTotalPages(totalPages);
      const startIndex = (page - 1) * perPage;
      const usersForPage = data.slice(startIndex, startIndex + perPage);
      setUsers(usersForPage);      
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };  

  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
    fetchData(currentPage + 1, perPage);
  };

  const prevPage = () => {
    if (currentPage > 1) {
    setCurrentPage(prevPage => prevPage - 1);
    fetchData(currentPage - 1, perPage);
    }
  };

  return (
    <>
          <Breadcrumb pageName="Users" />

            <div className="my-2">
            <button className="mb-2 inline-block rounded bg-primary px-4 py-2 font-medium text-gray hover:bg-opacity-90">
              Total Users: {users.length}
            </button>
            <Link
          to="/users/add"
          className="ml-4  inline-block rounded bg-success px-4 py-2 font-medium text-gray hover:bg-opacity-90"
        >
          New User
        </Link>
          </div>
          <div className="rounded-sm border border-stroke bg-white px-5 pb-2.5 pt-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
            {isLoading ? (
        <div className="flex h-screen items-center justify-center">
          <svg
            className="h-10 w-10 animate-spin text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0117.709 7H15v5.373L6.373 17H6v-4.709zM20 12h-4v-.373l-5.373-5.372L15 6.627V2h5.373A8.001 8.001 0 0120 12z"
            ></path>
          </svg>
        </div>
      ) : (
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-center dark:bg-meta-4">
                    <th className="min-w-[220px] px-4 py-4 text-left font-medium text-black dark:text-white xl:pl-11">
                      Name
                    </th>
                    <th className="min-w-[150px] px-4 py-4 font-medium text-black dark:text-white">
                      Email
                    </th>
                    <th className="min-w-[120px] px-4 py-4 font-medium text-black dark:text-white">
                      Contact Number
                    </th>
                    <th className="min-w-[120px] px-4 py-4 font-medium text-black dark:text-white">
                      Roles
                    </th>
                    <th className="px-4 py-4 font-medium text-black dark:text-white">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, key) => (
                    //  const isCustomer = user.roles.some(role => role.roleName === "ROLE_CUSTOMER");
                    //  if (isCustomer) {
                    //    return (
                    <tr key={key} className="">
                      <td className="border-b border-[#eee] px-4 py-4 pl-9 dark:border-strokedark xl:pl-11">
                        <div className="flex   gap-2">
                          <h5 className="text-left font-medium text-black dark:text-white">
                            {user.firstName} {user.lastName}
                          </h5>
                          {user.status === "Active" && (
                            <img src={Tick} alt="User" className="h-6"/>

                          )}
                        </div>
                      </td>
                      <td className="border-b border-[#eee] px-4 py-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {user.email}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] px-4 py-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {user.contactNumber}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] px-4 py-4 pl-9 dark:border-strokedark xl:pl-11">
                        {user.roles.map((role, index) => (
                          <h5
                            key={index}
                            className="font-medium text-black dark:text-white"
                          >
                            {role.name === "ROLE_SUPERADMIN" &&
                              "Superadmin"}
                            {role.name === "ROLE_CUSTOMER" && "Customer"}
                            {role.name === "ROLE_ADMIN" && "Admin"}
                          </h5>
                        ))}
                      </td>

                      <td className="border-b border-[#eee] px-4 py-4 dark:border-strokedark">
                        <div className="flex items-center justify-center space-x-3.5">
                          <Link
                            to={`/users/details/${user.userId}`}
                            className="hover:text-primary"
                          >
                            {/* <Link href={{
                          pathname:"`/customer/details/",
                          query:{
                            id:{customer.customerId}
                          },
                        }}> */}
                            <svg
                              className="fill-primary"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                fill=""
                              />
                              <path
                                d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                fill=""
                              />
                            </svg>
                          </Link>
                          {/* {session && roles.includes("ROLE_ADMIN") ? (
                        <div></div>
                      ) : (
                      <button className="fill-primary">
                        <Link href={`/customer/delete/${user.userId}`}>
                          <svg
                            className="fill-primary"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                              fill=""
                            />
                            <path
                              d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                              fill=""
                            />
                            <path
                              d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                              fill=""
                            />
                            <path
                              d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                              fill=""
                            />
                          </svg>
                        </Link>
                      </button>
                      )} */}

                          <button className="fill-primary">
                            <Link to={`/users/update/${user.userId}`}>
                              <svg
                                className="feather feather-edit text-primary"
                                fill="none"
                                height="24"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                              </svg>
                            </Link>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              )}


            </div>

            
          </div>

          <div className="mt-4 flex items-center  justify-center space-x-6">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
        >
          Prev
        </button>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
        
    </>
  );
};

export default AllUsers;
