import axios from "axios";
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';

class AuthenticationService{
   
  login(username: string, password: string) {
    return axios
      .post(`${BASEURL}` + "user/login/", {
        username,
        password,
      },
      { headers: {
        'Content-type': 'application/json',
        'X-API-SECRET':`${API_SECRET}`,
        'X-API-KEY':`${API_KEY}`
      },})
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          const token = response.data.accessToken;
        const decodedToken = parseJwt(token);
        if (decodedToken && decodedToken.exp) {
          const expirationTime = decodedToken.exp * 1000;
          localStorage.setItem('tokenExpiration', expirationTime.toString())
        }
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem('tokenExpiration');
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    if (!this.isTokenExpired()) {
      return userStr;
    } else {
      this.logout();
    }
  return null;  
 } 

  isTokenExpired() {
    const expirationTimeStr = localStorage.getItem('tokenExpiration');
    if (!expirationTimeStr) {
      localStorage.removeItem("user");
      localStorage.removeItem('tokenExpiration');
      return true;
    }
    const expirationTime = parseInt(expirationTimeStr, 10); // Convert back to number
    return new Date().getTime() > expirationTime;
  }

  removeExpiredToken() {
    if (this.isTokenExpired()) {
      this.logout();
    }
  }

}
export default new AuthenticationService();

function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
}